import { Product, ProductAttribute, ProductAttributeValue } from "@wttb/product-configurator";

import { internalApiFetcher } from "../../../clients/httpFetchers";
import { PaginatedResult, ROUTES } from "../../../clients/routes-config";

export enum Calculator {
  Flat = "flat",
  Book = "book",
  Wide = "wide",
  Apparel = "apparel",
  Unit = "unit",
  CUSTOM = "custom",
}

export type AddValuesToProductPayload = {
  typeId: string;
  valueIds: string[];
  key?: string;
};

export const findProducts = async ({
  where,
  page,
  pageSize,
}: {
  where: Record<string, unknown>;
  page: number;
  pageSize: number;
}): Promise<PaginatedResult<Product>> => {
  return internalApiFetcher(`${ROUTES.products}/paginated`, {
    method: "GET",
    params: { where, page, pageSize },
  });
};

export const findProductFull = async (id: string): Promise<Product> => {
  return internalApiFetcher(`${ROUTES.products}/${id}/full`, { method: "GET" });
};

export const updateProduct = async (
  id: string,
  data: Omit<
    Product,
    "id" | "sku" | "price" | "attributes" | "warnings" | "deliveryRules" | "productionDays"
  >,
): Promise<Product> => {
  return internalApiFetcher(`${ROUTES.products}/${id}`, {
    method: "PUT",
    data,
  });
};

export const addValuesToProduct = async (
  productId: string,
  data: AddValuesToProductPayload,
): Promise<Product> => {
  return internalApiFetcher(`${ROUTES.products}/${productId}/values`, {
    method: "POST",
    data,
  });
};

export const reorderProductAttributes = async (
  productId: string,
  data: Pick<ProductAttribute, "id" | "displayIndex">[],
): Promise<ProductAttribute[]> => {
  return internalApiFetcher(`${ROUTES.products}/${productId}/values/reorder`, {
    method: "POST",
    data,
  });
};

export const copyProductConfig = async (
  productId: string,
  data: Pick<Product, "id">,
): Promise<Product> => {
  return internalApiFetcher(`${ROUTES.products}/${productId}/copy`, {
    method: "POST",
    data,
  });
};

export const getProductAttributeValuesByTypeName = async (
  id: string,
  typeName: string,
): Promise<PaginatedResult<ProductAttributeValue>> => {
  return internalApiFetcher(`${ROUTES.products}/${id}/values`, {
    method: "GET",
    params: {
      typeName,
    },
  });
};
